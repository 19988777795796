<template>
  <v-row dense>
    <v-col cols="12">
      <h6 class="text-color-title caption font-weight-bold mb-3">
        VERSE URL <span class="red--text">*</span>
      </h6>
      <v-text-field
        filled
        rounded
        class="rounded-lg text-body-2"
        placeholder="https://sample-url-from-bible-app.com/romans-1"
        dense
        :rules="[...verseURLRules]"
        v-model="formLocal.verse_url"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <h6 class="text-color-title caption font-weight-bold mb-3">
        VERSE <span class="red--text">*</span>
      </h6>
      <v-text-field
        filled
        rounded
        class="rounded-lg text-body-2"
        placeholder="Romans 12:3"
        dense
        v-model="formLocal.verse"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <h6 class="text-color-title caption font-weight-bold mb-3">
        VERSE TEXT <span class="red--text">*</span>
      </h6>
      <v-textarea
        filled
        rounded
        class="rounded-lg text-body-2"
        placeholder="Because of the privilege and authority God has given me, I give each of you this warning: Don’t think you are better than you really are. Be honest in your evaluation of yourselves, measuring yourselves by the faith God has given us."
        dense
        v-model="formLocal.verse_text"
      ></v-textarea>
    </v-col>

    <v-col cols="12">
      <h6 class="text-color-title caption font-weight-bold mb-3">
        SCRIPTURE QUESTION <span class="red--text">*</span>
      </h6>
      <v-textarea
        filled
        rounded
        class="rounded-lg text-body-2"
        placeholder="How can you trust God’s plan for your life?"
        dense
        rows="1"
        v-model="formLocal.scripture_question"
      ></v-textarea>
    </v-col>

    <v-col cols="12">
      <div class="d-flex align-center align-content-center mb-3">
        <h6 class="text-color-title caption font-weight-bold mr-2">
          SCRIPTURE OPTIONS
        </h6>
        <v-btn
          x-small
          fab
          depressed
          color="cyan"
          dark
          :disabled="formLocal.scripture_options.length === 3"
          @click="addScriptureOptions"
        >
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
      </div>
      <template v-for="(scriptureOption, index) in formLocal.scripture_options">
        <v-textarea
          filled
          rounded
          class="rounded-lg text-body-2"
          :placeholder="`Option #${index + 1}`"
          dense
          rows="1"
          :key="index"
          hint="Required Field"
          persistent-hint
          v-model="scriptureOption.option"
        >
          <template v-slot:append-outer>
            <v-btn icon @click="removeScriptureOption(index)"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </template>
        </v-textarea>
      </template>
    </v-col>

    <v-col cols="12" class="mt-8">
      <div class="text-center mb-3">
        <h6 class="black--text subtitle-1 font-weight-bold ">
          When would you like to publish this? <span class="red--text">*</span>
        </h6>
      </div>
      <div>
        <v-date-picker
          full-width
          class="date-picker-no-header"
          v-model="formLocal.scheduled_at"
        ></v-date-picker>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    form: Object,
    itemId: [Number, null]
  },

  data() {
    return {
      formLocal: Object.assign({}, this.form),
      valid: false,
      // timePicker: false,
      verseURLRules: [
        v =>
          /^https:\/\/www.bible.com\/bible\/+([A-Z0-9])+\/([\w.]+)/.test(v) ||
          "Must be a valid verse URL"
      ]
    };
  },

  watch: {
    "form.verse"(value) {
      this.formLocal.verse = value;
    },
    "form.verse_text"(value) {
      this.formLocal.verse_text = value;
    },
    "form.scheduled_at"(value) {
      this.formLocal.scheduled_at = value;
    },
    "form.verse_url"(value) {
      this.formLocal.verse_url = value;
    },
    "form.scripture_question"(value) {
      this.formLocal.scripture_question = value;
    },
    "form.scripture_options"(value) {
      this.formLocal.scripture_options = value;
    },
    "formLocal.verse"(value) {
      this.$emit("update:form", { ...this.formLocal, verse: value });
    },
    "formLocal.verse_text"(value) {
      this.$emit("update:form", { ...this.formLocal, verse_text: value });
    },
    "formLocal.scheduled_at"(value) {
      this.$emit("update:form", { ...this.formLocal, scheduled_at: value });
    },
    "formLocal.verse_url"(value) {
      this.$emit("update:form", { ...this.formLocal, verse_url: value });
    },
    "formLocal.scripture_question"(value) {
      this.$emit("update:form", {
        ...this.formLocal,
        scripture_question: value
      });
    },
    "formLocal.scripture_options"(value) {
      this.$emit("update:form", {
        ...this.formLocal,
        scripture_options: value
      });
    }
  },

  methods: {
    addScriptureOptions() {
      this.formLocal.scripture_options = [
        ...this.formLocal.scripture_options,
        { option: "" }
      ];
    },

    removeScriptureOption(index) {
      this.formLocal.scripture_options = this.formLocal.scripture_options.filter(
        (_, _index) => index !== _index
      );
    }
  }
};
</script>
